import {useEffect, useMemo, useRef, useState} from "react";
import {isEmpty} from "lodash";

import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import {drawMesh} from "../utils/detection";
import {useCameraDimensions} from "./useCameraDimensions";

 let intervalId = 0

export const useFaceDetectionWebcam = () => {
    const {width, height} = useCameraDimensions()

    const cameraRef = useRef()
    const canvasRef = useRef(null)

    const [videoDem, handleVideoDem] = useState({w: 0, h: 0})
    const [cameraFacingMode, handleCameraFacingMode] = useState('user')
    const [imageData, handleImageData] = useState('');
    const [cameraReady, setCameraReady] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [faces, setFaces] = useState([])

    const facesAvailable = useMemo(() => !isEmpty(faces), [faces])

    useEffect(() => {
        if(!cameraReady){
            clearInterval(intervalId)
        }
    }, [cameraReady]);

    useEffect(() => {
        const loadModel = async () => {
            const model = await faceLandmarksDetection.load(
                faceLandmarksDetection.SupportedPackages.mediapipeFacemesh,
                {maxFaces: 1}
            );

            setIsLoading(false)

            intervalId = setInterval(() => {
                detectFaces(model)
            }, 20)
        }

        loadModel()

        return () => {
            clearInterval(intervalId);
        };
    }, [])

    const detectFaces = async (detector) => {
        if (cameraRef.current?.video?.readyState === 4) {
            const videoTag = cameraRef.current?.video
            const canvasTag = canvasRef.current;

            // videoTag.width = width;
            // videoTag.height = height;

            canvasTag.width = videoTag.videoWidth;
            canvasTag.height = videoTag.videoHeight

            const predictions = await detector.estimateFaces({input: videoTag});
            setFaces(predictions)

            const canvasCtx = canvasTag.getContext('2d');

            canvasCtx.drawImage(
                videoTag, 0, 0, canvasTag.width, canvasTag.height);

            requestAnimationFrame(() => {
                drawMesh(predictions, canvasCtx)
            });
        }
    };

    const captureImage = async () => {
        //take photo
        try {
            const video = cameraRef.current
            const canvas = canvasRef.current

            const context = canvas.getContext('2d');
            context?.drawImage(video, 0, 0, canvas.width, canvas.height);

            const imageData1 = canvas.toDataURL('image/png', 1.0);
            handleImageData(imageData1)
            return imageData1
        } catch (e) {
            console.log(e);
            return ''
        }
    }

    return {
        cameraRef,
        canvasRef,
        faces,
        setCameraReady,
        cameraReady,
        facesAvailable,
        isLoading,
        captureImage
    }

}

