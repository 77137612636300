import {useFindSkinAnalysisAfterPayment, usePayForSkinAnalysis} from "../hooks/api/useSkinAnalysisApi";
import {useForm} from "react-hook-form";
import {ANALYSIS_STATUS} from "../constants/status";
import {useEffect, useState} from "react";
import {BoldText} from "./BoldText";
import {Text} from "./Text";
import {Button} from "./Button";
import {getFormarttedNumber} from "../utils/utils";
import {ListItem} from "./ListItem";
import {Input} from "./Input";
import {LoadingElement} from "./LoadingElement";
import {ConfirmationBox} from "./ConfirmationBox";

export const PaymentForm = ({onSuccess, onFailed, analysis}) => {
    const analysisID = analysis?.tracking_key || ""

    const [startPoll, setStartPoll] = useState(false)

    const mutation = usePayForSkinAnalysis()
    const analysisQuery = useFindSkinAnalysisAfterPayment(analysisID, startPoll)

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({
        defaultValues: {
            phone: '254',
        },
        mode: 'onChange',
    })

    const onSubmit = ({phone}) => {
        if (!analysisID) {
            return
        }

        mutation.mutate({phone, id: analysisID}, {
            onSuccess: () => {
                // start polling for payment status
                setStartPoll(true)
            }
        })
    }

    if (analysisQuery.data?.data?.payment_status === ANALYSIS_STATUS.PAID) {
        onSuccess()
    }

    if (analysisQuery.data?.data?.payment_status === ANALYSIS_STATUS.FAILED) {
        onFailed()
    }

    useEffect(() => {
        setStartPoll(false)
    }, [analysisID])

    const isAwaitingPayment = startPoll && analysisQuery.data?.data?.payment_status === ANALYSIS_STATUS.PENDING

    const isLoading = mutation.isPending || analysisQuery.isLoading || isAwaitingPayment

    return (
        <>

            <div className="flex flex-col items-center">

                <div className={'flex flex-col items-center p-8 bg-black bg-opacity-50 max-w-lg'}>

                    {isLoading &&
                    <LoadingElement
                        title={"Waiting for payment"}
                        subTitle={"Please keep this window open while we process your payment"}
                    />
                    }

                    {!isLoading &&
                    <>
                        <BoldText className={'text-white uppercase text-[24px] text-center'}>
                            Complete your payment
                        </BoldText>

                        <Text className={'text-white mt-8'}>
                            Pay {analysis?.currency} {getFormarttedNumber(analysis.amount)}
                        </Text>

                        <div className={'mt-8'}>
                            <ListItem label={'Enter your M-PESA phone number and press "Confirm and Pay"'}/>
                            <ListItem label={'You will receive a popup on your phone. Enter your MPESA PIN'}/>
                            <ListItem label={'Sit back as we prepare your skin analysis results and recommendations.'}/>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} style={{marginTop: 40}}>
                            <Input
                                label="Mobile number"
                                placeholder={"Start with country code"}
                                {...register('phone', {
                                    required: 'Phone is required',
                                })}
                                error={!!errors.phone}
                                helperText={errors.phone?.message}
                            />

                            <Button
                                type="submit"
                                className={'w-full mt-8'}
                                disabled={!isValid || mutation.isPending}
                            >
                                Confirm and Pay
                            </Button>
                        </form>
                    </>
                    }

                </div>
            </div>
        </>
    )
}
