import {useNavigate, useRouteError} from "react-router-dom";
import {PageContainer} from "../components/PageContainer";
import {BoldText} from "../components/BoldText";
import {Text} from "../components/Text";

export const NotFound = () => {

    const error = useRouteError();
    const navigate = useNavigate();

    return (
        <PageContainer
            showClose
            onClose={() => {
                navigate("/")
            }}
        >
            <div className="flex flex-col items-center">
                <div className={'flex flex-col items-center p-8 bg-black bg-opacity-50 max-w-lg'}>
                    <BoldText className={'text-white uppercase text-[24px] text-center'}>
                        Oops!
                    </BoldText>

                    <Text className={'text-white mt-8 text-center'}>
                        Sorry, an unexpected error has occurred.
                    </Text>

                    <Text className={'text-white mt-8 text-center'}>
                        <i>{error.statusText || error.message}</i>
                    </Text>
                </div>
            </div>
        </PageContainer>
    )
}
