import {PageContainer} from "../components/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import {useFindSkinAnalysisResults} from "../hooks/api/useSkinAnalysisApi";
import {useEffect, useState} from "react";
import {ANALYSIS_STATUS} from "../constants/status";
import {BoldText} from "../components/BoldText";
import {LoadingElement} from "../components/LoadingElement";
import {Text} from "../components/Text";
import {ResultsItem} from "../components/ResultsItem";
import {ResultsHighlight} from "../components/ResultHighlights";
import {Divider} from "@mui/material";
import {Button} from "../components/Button";

export const Results = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const [enableFetch, setEnableFetch] = useState(true)

    const findAnalysisQuery = useFindSkinAnalysisResults(id, enableFetch)
    const skinAnalysis = findAnalysisQuery.data?.data

    const isPending = skinAnalysis?.status === ANALYSIS_STATUS.PENDING
    const isQueued = skinAnalysis?.status === ANALYSIS_STATUS.QUEUED
    const isReady = skinAnalysis?.status === ANALYSIS_STATUS.READY
    const isError = skinAnalysis?.status === ANALYSIS_STATUS.ERROR

    useEffect(() => {
        if (skinAnalysis?.status === ANALYSIS_STATUS.READY || skinAnalysis?.status === ANALYSIS_STATUS.ERROR) {
            setEnableFetch(false)
        }
    }, [skinAnalysis?.status, id])

    const results = JSON.parse(skinAnalysis?.score_info || "{}") || {}
    const areasOfFocus = JSON.parse(skinAnalysis?.areas_of_focus || "[]") || []
    const strengths = JSON.parse(skinAnalysis?.strengths || "[]") || []


    return (
        <PageContainer
            showClose
            onClose={() => {
                navigate("/")
            }}
        >
            {(skinAnalysis && !findAnalysisQuery.isLoading) &&
            <div className="flex flex-col desktop:flex-row mt-8 pb-8">
                <div className="basis-1/3">
                    <img
                        src={skinAnalysis?.face_data}
                        alt={"selfie"}
                        style={{
                            maxHeight: 500,
                            maxWidth: '100%'
                        }}
                    />
                </div>

                <div className="flex flex-col items-center basis-2/3 pl-4">
                    <div className={'flex flex-col items-center p-8 bg-black bg-opacity-80 w-full max-w-lg'}>

                        <BoldText className={'text-white text-[20px] text-center'}>
                            {isPending && "Awaiting payment confirmation"}
                            {isReady && "Your results are ready!"}
                            {isQueued && "Your results are being processed!"}
                            {isError && "We encountered an error"}
                        </BoldText>

                        {(isPending || isQueued) &&
                        <LoadingElement
                            title={"Preparing your results"}
                            subTitle={"Please keep this window open"}
                        />
                        }

                        {isError &&
                        <Text className={'text-white text-center mt-4'}>
                            {skinAnalysis?.error_message}
                        </Text>
                        }

                        <BoldText className={'text-white text-[24px] text-center mt-10'}>
                            Overall Results
                        </BoldText>

                        <ResultsItem title={"Acne"} index={results?.acne_score}/>
                        <ResultsItem title={"Roughness"} index={results?.rough_score}/>
                        <ResultsItem title={"Hyperpigmentation (Melanin)"} index={results?.melanin_score}/>
                        <ResultsItem title={"Pores"} index={results?.pores_score}/>
                        <ResultsItem title={"Hydration"} index={results?.water_score}/>
                        <ResultsItem title={"Dark Circles"} index={results?.dark_circle_score}/>
                        <ResultsItem title={"Blackheads"} index={results?.blackhead_score} isLast/>


                        <ResultsHighlight
                            title={"YOUR SKIN STRENGTHS"}
                            list={strengths}
                            style={{
                                marginTop: 24,
                                marginBottom: 48,
                            }}
                            isPositive
                        />

                        <Divider/>

                        <ResultsHighlight
                            title={"YOUR AREAS OF FOCUS"}
                            list={areasOfFocus}
                            style={{
                                marginTop: 48
                            }}
                            isPositive={false}
                        />

                        <Button className={'w-full'}>
                            See my recommendations
                        </Button>

                        <Button variant={'secondary'} className={'w-full mt-4'}>
                            Email my recommendations
                        </Button>
                    </div>

                </div>

            </div>
            }

        </PageContainer>
    )
}
