import {BoldText} from "./BoldText";
import {twMerge} from "tailwind-merge";
import {Text} from "./Text";
import {Button} from "./Button";

export const ConfirmationBox = ({title, subTitle, titleClass, subTitleClass, btnLabel, onBtnClick}) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <img src={'/img/check.svg'}/>

            <BoldText className={twMerge('text-white mt-6 text-xl', titleClass)}>
                {title}
            </BoldText>

            <Text className={twMerge('text-white mt-4 text-center', subTitleClass)}>
                {subTitle}
            </Text>

            <Button onClick={onBtnClick} className={'w-full mt-8'}>
                {btnLabel}
            </Button>

        </div>
    )
}
