import {useResponsive} from "./utils/useResponsive";
import {useMemo} from "react";

export const useCameraDimensions = () => {
    const {isMobileView, height, width} = useResponsive()

    return useMemo(() => {
        const dimensions = {}

        if (isMobileView) {
            dimensions.width = width - 24
            dimensions.height = height * 0.7
        } else {
            dimensions.width = 640
            dimensions.height = 480
        }

        return dimensions
    }, [width, height, isMobileView])
}
