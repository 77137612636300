import {useCallback, useEffect, useRef, useState} from "react";
import {Button} from "./Button";
import {CloseIcon} from "./CloseIcon";
import {useResponsive} from "../hooks/utils/useResponsive";
import {Header} from "./Header";
import {BoldText} from "./BoldText";
import * as faceapi from 'face-api.js';


const videoHeight = 480;
const videoWidth = 640;

export const CameraViewFaceApi = ({onSubmit, onCancel}) => {
    const {height, width} = useResponsive()
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    const [imageSource, setImageSource] = useState(null)
    const [modelsLoaded, setModelsLoaded] = useState(false);

    const capture = useCallback(() => {
        const imageSource = webcamRef.current.getScreenshot();
        setImageSource(imageSource)
    }, [])

    const submitImageData = useCallback(() => {
        if (imageSource) {
            onSubmit(imageSource)
        }
    }, [imageSource, onSubmit])

    const recapture = () => {
        setImageSource(null)
    }


    const handleVideoOnPlay = () => {
        setInterval(async () => {
            if (canvasRef && canvasRef.current) {
                canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(webcamRef.current);

                const videoWidth = webcamRef.current.video.videoWidth;
        const videoHeight = webcamRef.current.video.videoHeight;
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

                const displaySize = {
                    width: videoWidth,
                    height: videoHeight
                }

                canvasRef.current.width = videoWidth;
                canvasRef.current.height = videoHeight;

                faceapi.matchDimensions(canvasRef.current, displaySize);

                const detections = await faceapi.detectAllFaces(webcamRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();

                const resizedDetections = faceapi.resizeResults(detections, displaySize);

                canvasRef && canvasRef.current && canvasRef?.current.getContext('2d').clearRect(0, 0, videoWidth, videoHeight);
                canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
                canvasRef && canvasRef.current && faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
                canvasRef && canvasRef.current && faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);
            }
        }, 100)
    }

    const startVideo = () => {
        navigator.mediaDevices
            .getUserMedia({video: {}})
            .then(stream => {
                let video = webcamRef.current;
                video.srcObject = stream;
            })
            .catch(err => {
                console.error("error:", err);
            });
    }

    const closeWebcam = () => {
        webcamRef.current.pause();
        webcamRef.current.srcObject.getTracks()[0].stop();
        //setCaptureVideo(false);
    }

    useEffect(() => {
        const loadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + '/models';

            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
            ]).then(() => {
                setModelsLoaded(true)
                handleVideoOnPlay()
            });
        }

        loadModels();
    }, [])


    return (
        <div className={'h-screen bg-black overflow-y-auto'}>
            {!imageSource &&
                <>
                    <div className={'w-full h-full absolute top-0 left-0 bottom-0 bg-black'}>
                        <video
                            ref={webcamRef}
                            autoPlay
                        />
                        <canvas ref={canvasRef} className={'absolute'}/>
                    </div>
                </>
            }

            {!imageSource && (
                <>
                    <div className="absolute left-0 right-0 top-0">
                        <div className={'bg-black bg-opacity-50'}>
                            <div className="container w-full mx-auto px-4">
                                <Header showClose onClose={onCancel} className={'py-2'}/>
                            </div>
                        </div>

                        <div className={'flex flex-col items-center mt-4'}>
                            <BoldText className={'text-white text-center'}>
                                Position your face within the frame.
                            </BoldText>
                        </div>
                    </div>

                    <div
                        className="absolute left-0 right-0 bottom-0 bg-black bg-opacity-50 flex flex-row justify-between items-center px-4 py-4">

                        <div>
                            <CloseIcon onClick={onCancel}/>
                        </div>

                        <div>
                            <Button onClick={capture} className={'py-2'}>
                                Capture
                            </Button>
                        </div>

                    </div>

                </>
            )
            }

            {imageSource &&
                <div className={'container w-full mx-auto px-4'}>
                    <Header onClose={onCancel} showClose/>
                    <div className="flex flex-col items-center mt-4 p-2">
                        <div className={'border-2 border-white border-dashed'}>
                            <img
                                src={imageSource}
                                alt={"selfie"}
                                style={{
                                    height: 'auto',
                                    width: 'auto',
                                    objectFit: 'contain',
                                }}
                            />
                        </div>

                        <div className={'max-w-sm mt-8'}>
                            <Button onClick={submitImageData} className={'w-full'}>
                                Continue
                            </Button>

                            <Button onClick={recapture} className={'w-full mt-4'} variant={'secondary'}>
                                Recapture
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
