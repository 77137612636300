import {useMediaQuery} from "react-responsive";
import {
    MAX_DEVICE_WIDTH_DESKTOP,
    MAX_DEVICE_WIDTH_FORM,
    MAX_DEVICE_WIDTH_MOBILE,
    MAX_DEVICE_WIDTH_TABLET
} from "../../constants/responsive";

function useWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export const useResponsive = () => {
    const {width, height} = useWindowDimensions()
    const isDesktop = useMediaQuery({minWidth: MAX_DEVICE_WIDTH_TABLET + 1})
    const isTablet = useMediaQuery({minWidth: MAX_DEVICE_WIDTH_MOBILE + 1, maxWidth: MAX_DEVICE_WIDTH_TABLET})
    const isMobile = useMediaQuery({maxWidth: MAX_DEVICE_WIDTH_MOBILE})

    const isBigScreen = useMediaQuery({query: '(min-device-width: 1824px)'})

    const isPortrait = useMediaQuery({query: '(orientation: portrait)'})
    const isRetina = useMediaQuery({query: '(min-resolution: 2dppx)'})

    // might want to add other conditions later
    const isMobileView = isMobile && isPortrait

    return {
        isMobileView,
        isTablet,
        isPortrait,
        isBigScreen,
        isDesktop,
        isMobile,
        isRetina,
        width,
        height
    }
}

export const getMaxWidth = (isForm, useDesktopWidth) => {
    let contentWidth = MAX_DEVICE_WIDTH_TABLET

    if (isForm) {
        contentWidth = MAX_DEVICE_WIDTH_FORM
    }

    if (useDesktopWidth) {
        contentWidth = MAX_DEVICE_WIDTH_DESKTOP
    }

    return contentWidth
}
