import {template, templateSettings} from "lodash";
import {STRINGS} from "../constants/strings";
import Numeral from 'numeral'

export const buildAnalysisAIUrl = () => {
    return `${window.location.protocol}//${window.location.host}${STRINGS.SKIN_ANALYSIS_URL}`
}

export const buildUrl = (url, params) => {
    templateSettings.interpolate = /{{([\s\S]+?)}}/g

    const compiled = template(url)
    return compiled(params);
}

export const getShortNumber = (n) => Numeral(n).format('0.0a');

export const getShortNumberND =(n)=> Numeral(n).format('0a');

export const getFormarttedNumber = (n) => Numeral(n).format('0,0.00');

export const getFormarttedNumberND = (n) => Numeral(n).format('0,0');
