import {BaseModal} from "./BaseModal";
import React from "react";
import {Text} from "./Text";
import {Button} from "./Button";

export const TermsAndConditionsModal = ({open, setOpen, onAgreed}) => {

    return (
        <BaseModal title={"Terms & Conditions"} open={open} setOpen={setOpen}>
            <Text>
                Tui Beauty will process personal information about you to provide you with your analysis and
                recommendation. The selfie that you upload for our AI will be deleted after analysis, and the
                information retained by Tui Beauty will be used consistent with this Notice. If I elect to receive my
                results from Tui Beauty , I understand that certain limited information obtained from the analysis of my
                selfie will be stored by Tui Beauty .
            </Text>

            <div className={'mt-8'}>
                <Button
                    onClick={() => {
                        setOpen(false)
                        onAgreed?.()
                    }}
                    className={'w-full border-black'}
                >
                    I agree
                </Button>
            </div>

            <Button
                variant={'secondary'}
                className={'w-full mt-5'}
                onClick={e => setOpen(false)}
            >
                Cancel
            </Button>

        </BaseModal>
    )
}
