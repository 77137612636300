import {Text} from "./Text";

export const ResultsItem = ({title, index, isLast}) => {
    return (
        <div
            className={'flex flex-row justify-between w-full py-3'}
            style={{
                ...(!isLast ? {
                    borderBottom: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: "#D4D4D4",
                } : null)
            }}
        >
            <Text
                className={'font-semibold text-white'}
            >
                {title}
            </Text>

            <div>
                <Text className={'text-sm text-white'}>
                    {index}
                </Text>
            </div>

        </div>
    )
}
