import {twMerge} from "tailwind-merge";
import {Header} from "./Header";

export const PageContainer = ({children, className, showClose, onClose, showHeader = true}) => {
    return (
        <div
            className={twMerge(`h-screen bg-hero-africa-woman bg-fixed overflow-y-auto`, className)}
        >
            <div className={twMerge('container w-full mx-auto px-4')}>
                {showHeader &&
                <Header onClose={onClose} showClose={showClose}/>
                }
                {children}
            </div>
        </div>
    )
}
