import {Text} from "./Text";
import {twMerge} from "tailwind-merge";

export const ListItem = ({label, textClassName}) => {
    return (
        <div className={'flex flex-row py-2 items-center'}>
            <div className={'bg-white h-3 w-3 mr-4'}/>

            <Text className={twMerge('text-white', textClassName)}>
                {label}
            </Text>
        </div>
    )
}
