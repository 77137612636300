import {Button} from "./Button";
import {Link} from "react-router-dom";

export const LinkButton = ({children, to, ...props}) => {
    return (
        <Link to={to}>
            <Button {...props}>
                {children}
            </Button>
        </Link>

    )
}
