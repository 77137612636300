import {Box, Dialog, DialogContent, IconButton} from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import {BoldText} from "./BoldText";

export const BaseModal = ({open, setOpen, children, title, maxWidth = 'sm', onClose}) => {

    const handleClose = () => {
        setOpen(false)
        onClose?.()
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
        >
            <Box
                p={2}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <BoldText size={18}>
                    {title || "Dialog"}
                </BoldText>

                <IconButton aria-label="close" size="medium" onClick={handleClose}>
                    <CloseIcon fontSize="inherit" style={{color: "#000"}}/>
                </IconButton>
            </Box>

            <DialogContent style={{paddingTop: 16}}>
                {children}
            </DialogContent>
        </Dialog>
    )
}
