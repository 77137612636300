import {Logo} from "./Logo";
import {CloseIcon} from "./CloseIcon";
import {twMerge} from "tailwind-merge";
import {Link} from "react-router-dom";

export const Header = ({showClose, onClose, className}) => {
    return (
        <div className={twMerge("w-full flex flex-row items-center justify-between py-8", className)}>
            <Link to={"/"}>
                <Logo/>
            </Link>
            <div>
                {showClose &&
                <CloseIcon onClick={onClose}/>
                }
            </div>
        </div>
    )
}
