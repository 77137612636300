import {useCallback, useEffect, useState} from "react";
import {Button} from "./Button";
import Webcam from "react-webcam";
import {Header} from "./Header";
import {useFaceDetection} from "../hooks/useFaceDetection";
import {LoadingElement} from "./LoadingElement";
import {StatusItem} from "./StatusItem";
import {useCameraDimensions} from "../hooks/useCameraDimensions";
import {useNotifications} from "../hooks/utils/useNotifications";
import {useFaceDetectionWebcam} from "../hooks/useFaceDetectionWebcam";

const frameStyle = {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    zindex: 9,
    borderRadius: 16,
}

export const CameraView = ({onSubmit, onCancel}) => {
    const {showErrorNotification} = useNotifications()
    const {width, height} = useCameraDimensions()
    const {cameraRef, canvasRef, setCameraReady, isLoading, facesAvailable} = useFaceDetectionWebcam()

    const [imageSource, setImageSource] = useState(null)

    const capture = useCallback(() => {
        if (!facesAvailable) {
            showErrorNotification("No faces detected")
            return
        }

        const imageSource = cameraRef.current.getScreenshot();
        setImageSource(imageSource)
    }, [cameraRef, facesAvailable, showErrorNotification])

    const submitImageData = useCallback(() => {
        if (imageSource) {
            onSubmit(imageSource)
        }
    }, [imageSource, onSubmit])

    const recapture = () => {
        setImageSource(null)
    }


    useEffect(() => {
        if (imageSource) return;

        setCameraReady(true)
    }, [imageSource, setCameraReady])


    return (
        <div className={'h-screen bg-black overflow-y-auto'}>


            {!imageSource &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: 8,
                }}>
                    <StatusItem label={facesAvailable ? "Face detected" : "No face detected"}
                                isActive={facesAvailable}/>

                    <div className={'mt-3 p-2'} style={{width, height}}>
                        {isLoading && <LoadingElement/>}
                        <>
                            <Webcam
                                ref={cameraRef}
                                mirrored={true}
                                screenshotFormat="image/jpeg"
                                style={{...frameStyle, width, height}}
                                width={width}
                                height={height}
                            />
                            <canvas
                                ref={canvasRef}
                                width={width}
                                height={height}
                                style={{...frameStyle, width, height}}
                            />
                        </>
                    </div>

                    {!isLoading &&
                        <div
                            className="mt-4 flex flex-row justify-between items-center px-4 py-4">
                            <div className={'mr-2'}>
                                <Button onClick={capture} className={'py-2'}>
                                    Capture
                                </Button>
                            </div>

                            <div className={'ml-2'}>
                                <Button variant={'secondary'} onClick={onCancel} className={'py-2'}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    }

                </div>
            }


            {imageSource &&
                <div className={'container w-full mx-auto px-4'}>
                    <Header onClose={onCancel} showClose={false}/>
                    <div className="flex flex-col items-center mt-4 p-2">
                        <div className={'border-2 border-white border-dashed'}>
                            <img
                                src={imageSource}
                                alt={"selfie"}
                                style={{
                                    height: 'auto',
                                    width: 'auto',
                                    objectFit: 'contain',
                                }}
                            />
                        </div>

                        <div className={'max-w-sm mt-8'}>
                            <Button onClick={submitImageData} className={'w-full'}>
                                Continue
                            </Button>

                            <Button onClick={recapture} className={'w-full mt-4'} variant={'secondary'}>
                                Recapture
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
