import {TextField} from "@mui/material";
import React from "react";
import {Text} from "./Text";

export const Input = React.forwardRef((props, ref) => {

    return (
        <>
            {/*<TextField*/}
            {/*    ref={ref}*/}
            {/*    fullWidth*/}
            {/*    className={'font-josefin px-2 py-4 bg-white w-full text-black'}*/}
            {/*    {...props}*/}
            {/*/>*/}

            {props.label && <div className={'mb-2'}>
                <Text className={'text-white'}>{props.label}</Text>
            </div>}

            <input
                ref={ref}
                {...props}
                className={'rounded-lg font-josefin px-2 py-4 bg-white w-full text-black'}
            />
        </>
    )
})
