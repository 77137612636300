import {twMerge} from "tailwind-merge";
import {Text} from "./Text";

export const Button = ({className, textClassName, children, variant = 'primary', onClick, ...rest}) => {
    return (
        <button
            onClick={onClick}
            className={twMerge(
                'rounded-lg px-6 py-4 border-2 border-white',
                variant === 'primary' ? 'bg-white ' : 'bg-black',
                className
            )}
            {...rest}
        >
            <Text className={twMerge(
                'font-bold',
                variant === 'primary' ? 'text-black' : 'text-white',
                textClassName,
            )}>
                {children}
            </Text>
        </button>
    )
}
