import {Text} from "./Text";
import {twMerge} from "tailwind-merge";

export const StatusItem = ({label, textClassName, isActive}) => {
    return (
        <div className={'flex flex-row py-2 items-center'}>
            {isActive ?
                <div className={'bg-green-700 h-3 w-3 mr-4'} style={{borderRadius: 6}}/> :
                <div className={'bg-red-700 h-3 w-3 mr-4'} style={{borderRadius: 6}}/>
            }

            <Text className={twMerge('text-white', textClassName)}>
                {label}
            </Text>
        </div>
    )
}
