import axios from 'axios';
import {Endpoints} from "./endpoints";
import {retrieveItem} from "../utils/storage";

const api = axios.create({
    baseURL: Endpoints.BASE_URL,
})

// Request interceptor for API calls
// Attaches token to header
api.interceptors.request.use(
    (config) => {
        return new Promise((resolve, reject) => {
            const token = retrieveItem('token')
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }

            resolve(config)
        })
    },
    error => {
        return Promise.reject(error);
    })

// Response interceptor for API calls
// Responds to authentication errors
api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            // log out user
            // window.localStorage.clear()
            // if (window.location.pathname !== "/login") {
            //     window.location.assign("/login")
            // }
        }

        return Promise.reject(error)
    }
);

export default api
