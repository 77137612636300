import {createContext, useContext, useState} from 'react'
import {Alert, Snackbar} from "@mui/material";
import {isEmpty} from "lodash";

const NotificationsContext = createContext({})

const NotificationsProvider = ({children}) => {
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('success')

    const showNotification = (message, severityStatus = 'success') => {
        if (isEmpty(message)) {
            return
        }

        if (show) {
            setShow(false)
        }

        setSeverity(severityStatus)
        setMessage(message)

        setShow(true)
    }

    const showErrorNotification = (message= '') => {
        showNotification(message, 'error')
    }

    const handleClose = () => {
        setShow(false)
    };

    return (
        <NotificationsContext.Provider
            value={{
                showNotification,
                showErrorNotification,
            }}
        >
            <Snackbar
                open={show}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
            >
                <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
            {children}
        </NotificationsContext.Provider>
    )
}

export const useNotifications = () => useContext(NotificationsContext)

export default NotificationsProvider
