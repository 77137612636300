import {PageContainer} from "../components/PageContainer";
import {Logo} from "../components/Logo";
import {BoldText} from "../components/BoldText";
import {Button} from "../components/Button";
import {ListItem} from "../components/ListItem";
import {LinkButton} from "../components/LinkButton";
import {useNavigate} from "react-router-dom";

export const GetStarted = () => {
    const navigate = useNavigate();

    return (
        <PageContainer
            showClose
            onClose={() => {
                navigate("/")
            }}
        >
            <div className="flex flex-col desktop:flex-row">
                <div className="basis-1/3 bg-black bg-opacity-50 px-8">

                    <div className={'mt-12'}>
                        <BoldText className={'text-white uppercase text-[24px]'}>
                            Start your analysis
                        </BoldText>

                        <div className={'mt-8'}>
                            <ListItem label={'Remove make up and glasses.'}/>
                            <ListItem label={'Pull your hair back.'}/>
                            <ListItem label={'Face camera and keep expression neutral.'}/>
                            <ListItem label={'Stay in well-lit, natural light.'}/>
                        </div>

                    </div>

                    <div className={'mt-8'}>
                        <LinkButton to={"/take-selfie"} className={'w-full'}>
                            Take a selfie
                        </LinkButton>
                    </div>

                    <Button variant={'secondary'} className={'w-full mt-5'}>
                        Upload a photo
                    </Button>

                </div>
                <div className="basis-1/2">

                </div>
            </div>
        </PageContainer>
    )
}
